<template>
  <b-card
    no-body
    class="mb-1"
  >
    <b-card-header class="py-1">
      <h4 class="card-title font-weight-bolder">
        {{ $t('filters') }}
      </h4>
      <div class="d-flex-center justify-content-end gap-2">
        <!-- SEARCH BUTTON -->
        <b-button
          :variant="!emptyFilters ? 'warning' : ''"
          class="min-width-120"
          :class="{ 'cursor-not-allowed': emptyFilters }"
          :disabled="emptyFilters"
          @click="onUpdateFilters"
        >
          <span class="text-nowrap d-flex-center gap-1">
            <feather-icon
              class="cursor-pointer"
              icon="SearchIcon"
              size="16"
            />
            {{ $t('search') }}
          </span>
        </b-button>

        <!-- CLEAR BUTTON -->
        <b-button
          :variant="!emptyFilters ? 'danger' : ''"
          :class="{ 'cursor-not-allowed': emptyFilters, 'min-width-120': true }"
          :disabled="emptyFilters"
          @click="onClearFilters"
        >
          <span class="text-nowrap d-flex-center gap-1">
            <feather-icon
              class="cursor-pointer"
              icon="XOctagonIcon"
              size="16"
            />
            {{ $t('clear') }}
          </span>
        </b-button>
      </div>
    </b-card-header>

    <b-card-body class="py-0">
      <b-row class="mb-0">
        <!-- START DATE -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <template
              #label
              for="startDate"
            >
              <h5 class="mb-0">
                {{ $t('topup.startDate') }}
              </h5>
            </template>
            <flat-pickr
              id="startDate"
              v-model="startDate"
              class="form-control"
              :placeholder="$t('topup.placeholderSelectDate')"
              :config="{
                allowInput: true,
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                altInput: true,
                maxDate: maxStartDate,
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
            />
          </b-form-group>
        </b-col>

        <!-- END DATE -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <template
              #label
              for="endDate"
            >
              <h5 class="mb-0">
                {{ $t('topup.endDate') }}
              </h5>
            </template>
            <flat-pickr
              id="endDate"
              v-model="endDate"
              class="form-control"
              :placeholder="$t('topup.placeholderSelectDate')"
              :config="{
                allowInput: true,
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                altInput: true,
                minDate: minEndDate,
                maxDate: today,
                locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
                disableMobile: true,
              }"
            />
          </b-form-group>
        </b-col>

        <!-- TOPUP STATUS -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <template
              #label
              for="paymentStatus"
            >
              <h5 class="mb-0">
                {{ $t('topup.paymentStatus') }}
              </h5>
            </template>
            <v-select
              id="paymentStatus"
              v-model="selectedStatus"
              clearable
              :placeholder="$t('placeholderSelect')"
              :options="statusTopupOptions"
              :reduce="val => val.value"
            >
              <template #option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>
              <template #selected-option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormGroup,
} from 'bootstrap-vue'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormGroup,
    vSelect: () => import('vue-select'),
    flatPickr: () => import('vue-flatpickr-component'),
  },
  props: {
    searchKeywords: {
      type: String,
      default: '',
    },
    statusTopupOptions: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const selectedStatus = ref('')
    const startDate = ref('')
    const endDate = ref('')

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    const maxStartDate = computed(() => (endDate.value ? endDate.value : today))
    const minEndDate = computed(() => (startDate.value ? startDate.value : undefined))
    const emptyFilters = computed(() => !(
      selectedStatus.value
      || startDate.value
      || endDate.value
      || props.searchKeywords
    ))

    const onClearFilters = () => {
      selectedStatus.value = ''
      startDate.value = ''
      endDate.value = ''
      emit('update:searchKeywords', '')
      emit('refetch-data-without-filters')
    }

    const onUpdateFilters = () => {
      const filtersData = {
        paymentStatus: selectedStatus.value || undefined,
        startDate: startDate.value || undefined,
        endDate: endDate.value || undefined,
      }
      emit('refetch-data-with-filters', filtersData)
    }

    watch(emptyFilters, val => {
      if (val) emit('refetch-data-without-filters')
    })

    return {
      selectedStatus,
      startDate,
      endDate,
      today,
      maxStartDate,
      minEndDate,
      emptyFilters,

      onClearFilters,
      onUpdateFilters,
      Vietnamese,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
